import React from "react";

import classnames from "classnames";
import { isEmpty, isNaN } from 'lodash'
const AddClientDelivery = ({
  changeDeliveryCheck,
  changeDeliveryValue,
  showDelivery,
  deliveryDisabled,
  deliveryLabel,
  deliveryError,
  deliveryValue,
}) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="form-group row">
          <label className="col-7 col-form-label label">Delivery</label>
          <div className="col-4">
            <input
              type="checkbox"
              checked={showDelivery}
              onChange={changeDeliveryCheck}
              style={{ marginTop: "10px" }}
              disabled={deliveryDisabled}
            />
          </div>
        </div>
      </div>
      {showDelivery && (
        <div className="col-md-6">
          <div className="form-group row ">
            <label htmlFor="" className="col-sm-8 col-form-label label">
              {deliveryLabel}
            </label>
            <div className="col-sm-4 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                disabled={deliveryDisabled}
                className={classnames("form-control show-number", {
                  "is-invalid": deliveryError,
                })}
                defaultValue={deliveryValue || 0}
                onKeyDown={(event) => {
                  if (event.keyCode == 109 || event.keyCode == 189 || event.keyCode == 107 || event.keyCode == 187) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  event.preventDefault();
                }}
                id="number"
                min="0"
                onChange={(e) => {
                  changeDeliveryValue("deliverycharges", isEmpty(e.target.value) || isNaN(e.target.value) ? 0 : e.target.valueAsNumber);
                }
                }
                step="any"
              />
              <small>{deliveryError}</small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddClientDelivery;
