import React from "react";
import classnames from "classnames";
import { imageUtility } from "../../../common/imageUtility";

const EditOrderTable = ({
  currorder,
  loading,
  changeStatus,
  changeAllLineItemsStatus,
  updateAndSubmit,
}) => {
  const messages = {
    label: {
      sku: "SKU",
      name: "Name",
      image: "Image",
      quantity: "Quantity",
      unit: "Unit",
      comment: "Comment",
      reviewers: "Reviewers",
      status: "Status",
      availableStock: "Available Stock",
    },
    options: {
      approved: "Approved",
      canceled: "Canceled",
      requested: "Requested",
      changed: "Changed",
      each: "Each",
    },
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table className="table mt-5 order-table table-sm ">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{messages.label.sku}</th>
              <th scope="col">{messages.label.name}</th>
              <th scope="col">{messages.label.image}</th>
              <th scope="col">{messages.label.quantity}</th>
              <th scope="col">{messages.label.unit}</th>
              <th scope="col">{messages.label.comment}</th>
              <th className="text-center">{messages.label.reviewers}</th>
              <th>{messages.label.availableStock}</th>
              <th>{messages.label.status}</th>
            </tr>
          </thead>
          <tbody>
            {currorder.linesItems &&
              currorder.linesItems.map((item) => (
                <tr key={item._id}>
                  <td
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={item.code}
                  >
                    {item.code}
                  </td>
                  <td
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={item.name}
                  >
                    {item.name.slice(0, 10)}....
                  </td>
                  <td>
                    <img
                      src={imageUtility(Array.isArray(item?.imageURL) ? item?.imageURL?.[0]?.replace(/ /g, "%20") : item?.imageURL?.replace(/ /g, "%20"))}
                      alt="Sku Image"
                      className="table-image border"
                    />
                  </td>
                  <td>
                    <input
                      disabled={
                        currorder?.status === "Submitted" ? true : false
                      }
                      type="number"
                      className="btn-small form-control in-w product-quan addQuantity"
                      defaultValue={item.quantity}
                      min={item.minimumOrder || 1}
                      // step={item.minimumOrder}
                      onKeyDown={(event) => {
                        if (event.keyCode == 109 || event.keyCode == 189 || event.keyCode == 107 || event.keyCode == 187 || event.keyCode == 110 || event.keyCode == 190) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onChange={(e) => {
                        if (e.target.valueAsNumber <= 999999) {
                          item.quantity = e.target.valueAsNumber;
                          item.totalPrice = parseFloat(item.quantity) * parseFloat(item.unitPrice)
                        }
                      }}
                    />
                  </td>
                  <td>
                    <select
                      disabled={
                        currorder?.status === "Submitted" ? true : false
                      }
                      className="sel-unit form-control selectList"
                    >
                      <option value={item.unit}>{messages.options.each}</option>
                    </select>
                  </td>
                  <td>
                    <input
                      disabled={
                        currorder?.status === "Submitted" ? true : false
                      }
                      type="text"
                      className="form-control comm-w addComment"
                      onChange={(e) => (item.comment = e.target.value)}
                      defaultValue={item.comment}
                    />
                  </td>

                  {item.productManager ? (
                    <td className="text-center">{item.productManager}</td>
                  ) : (
                    <td></td>
                  )}
                  <td className="text-center">{item.currentStock}</td>
                  <td>
                    <select
                      disabled={
                        currorder?.status === "Submitted" ? true : false
                      }
                      className="sel-status form-control selectList"
                      onChange={(e) => {
                        changeStatus(item.id, e.target.value);
                      }}
                      value={item.status}

                    >
                      <option value="approved">
                        {messages.options.approved}
                      </option>
                      <option value="cancelled">
                        {messages.options.canceled}
                      </option>
                      <option value="requested">
                        {messages.options.requested}
                      </option>
                      <option value="changed" disabled={true}>
                        {messages.options.changed}
                      </option>
                    </select>
                  </td>
                </tr>
              ))}
            {currorder.linesItems && (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <button
                    className={classnames("btn button w-100", {
                      disabledBtn:
                        currorder?.status === "Submitted" ? true : false,
                    })}
                    onClick={() => changeAllLineItemsStatus("approved")}
                    disabled={currorder?.status === "Submitted" ? true : false}
                  >
                    Approve All
                  </button>
                </td>
              </tr>
            )}
            {currorder.linesItems && (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <button
                    type="submit"
                    className={classnames("btn button w-100", {
                      disabledBtn:
                        currorder?.status == "Submitted" || loading
                          ? true
                          : false,
                    })}
                    disabled={
                      currorder?.status == "Submitted" || loading ? true : false
                    }
                    onClick={(e) => {
                      updateAndSubmit(currorder);
                    }}
                  >
                    Update & Submit
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EditOrderTable;
