import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { allowPostiveNumber } from "../../../common/utilityService";

const EditSkuForm = ({
  updateAndSubmit,
  selctedCategories,
  sku,
  pricingMode,
  option,
  unitPriceDisable,
  currCategory,
  productCustomBackgroundColor,
  productCustomTitleColor,
  customTitleFunction,
  customBackgroundFunction,
  productPriorityNumber,
  priorityNumberFunction


}) => {
  const messages = {
    label: {
      SkuNo: "SKU No",
      name: "Name",
      client: "Client",
      status: "Status",
      lowStockLevel: "Low Stock Level",
      pricingMode: "Pricing Mode",
      orderUnit: "Order Unit",
      unitPrice: "Unit Price",
      minOrder: "Minimum Order",
      categories: "Categorie(s)",
      rounding: "Rounding",
      productColor: "Background Color",
      productTitleColor: "Title Color",
      productPriorityNumber: "Priority Number"

    },
    button: {
      submit: "Update And Submit",
    },
  };

  const setMinimumOrder = (value) => {
    const integerVal = parseInt(value);
    if (integerVal) {
      sku.minimumOrder = value;
    } else {
    }
  };

  const setRoundingNumber = (value) => {
    const integerVal = parseInt(value);
    sku.roundingNumber = integerVal ? value : 0;
  };

  const setProductCustomTitleColor = (color) => {
    productCustomTitleColor = color;
    customTitleFunction(productCustomTitleColor);
  };

  const setProductCustomBackgroundColor = (color) => {
    productCustomBackgroundColor = color;
    customBackgroundFunction(productCustomBackgroundColor);
  }
  const setProductPriorityNumber = (value) => {
    const integerVal = parseInt(value);
    productPriorityNumber = integerVal ? value : 0;
    priorityNumberFunction(productPriorityNumber)
  }

  return (
    <form className="border p-5" onSubmit={updateAndSubmit}>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.SkuNo}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                disabled
                value={sku.id}
                data-toggle="tooltip"
                data-placement="bottom"
                title={sku.id}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.name}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                disabled
                value={sku.description}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.client}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                value={sku.clientname}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.status}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                disabled
                value={sku.status}
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.lowStockLevel}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                className="form-control"
                defaultValue={sku.lowStockLevel}
                required
                onKeyDown={allowPostiveNumber}
                onPaste={(e) => e.preventDefault()}
                min="0"
                onChange={(e) => (sku.lowStockLevel = e.target.valueAsNumber)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.pricingMode}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                disabled
                placeholder={pricingMode}
                min="0"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.orderUnit}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="text"
                required
                className="form-control"
                disabled
                value={sku.unit}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.unitPrice}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                required
                name="price"
                className="form-control"
                defaultValue={sku.unitPrice}
                disabled={unitPriceDisable}
                onKeyDown={(event) => {
                  if (event.keyCode === 109 || event.keyCode === 189 || event.keyCode === 107 || event.keyCode === 187) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  event.preventDefault();
                }}
                onChange={(e) => (sku.unitPrice = e.target.valueAsNumber)}
                min="0"
                step="any"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.minOrder}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                required
                className="form-control"
                defaultValue={sku.minimumOrder}
                onKeyDown={allowPostiveNumber}
                onChange={(e) => setMinimumOrder(e.target.value)}
                min="1"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label className="col-lg-4 col-form-label">
              {messages.label.categories}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <div className="selectList">
                <Multiselect
                  displayValue="name"
                  options={option}
                  selectedValues={currCategory}
                  onSelect={(a, b) => {
                    selctedCategories(a);
                  }}
                  onRemove={(a, b) => {
                    selctedCategories(a);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.rounding}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                required
                className="form-control"
                defaultValue={sku.roundingNumber}
                onKeyDown={allowPostiveNumber}
                onChange={(e) => setRoundingNumber(e.target.value)}
                min="1"
                disabled
              />
            </div>
          </div>
        </div>


        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.productTitleColor}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="color"
                className="form-control"
                value={sku.productCustomTitleColor}
                onChange={
                            (e) =>  setProductCustomTitleColor(e.target.value) 
                }
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.productColor}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="color"
                className="form-control"
                value={sku.productCustomBackgroundColor}
                onChange={
                            (e) => setProductCustomBackgroundColor(e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row ">
            <label htmlFor="" className="col-lg-4 col-form-label">
              {messages.label.productPriorityNumber}
            </label>
            <div className="col-lg-8 px-0 px-md-3 px-sm-3">
              <input
                type="number"
                className="form-control"
                defaultValue={sku.productPriorityNumber}
                // onKeyDown={allowPostiveNumber}
                onChange={(e) => setProductPriorityNumber(e.target.value)}
                min="0"
                
              />
            </div>
          </div>
        </div>
      </div>
      <input
        type="submit"
        value={messages.button.submit}
        className="btn btn-success"
        style={{ float: "right" }}
      />
    </form>
  );
};

export default EditSkuForm;