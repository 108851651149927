import React, { useState } from "react";
import Navbar from "../../utilsComponent/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { useContext } from "react";
import AdminContext from "../../../contextAPI/AdminContext";
import { useEffect } from "react";
import { size } from "lodash";
import ViewOrderTable from "./ViewOrderTable";
import ViewOrderCard from "./ViewOrderCard";
import ALerts from "../../utilsComponent/ALerts";
import { find, isEmpty } from "lodash";
import ViewShippingAddressCard from './ViewSHippingAddressCard'
import ViewBillingAddressCard from './ViewBillingAddressCard'

const ViewOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    currorder,
    currOrder,
    updateOrder,
    setCurrentUser,
    clearMsg,
    clearCurrentOrder,
    msg,
    loading,
  } = useContext(AdminContext);

  const [currentOrder, setCurrentOrder] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  const messages = {
    heading: {
      orderReview: "Order Review",
    },
  };

  useEffect(() => {
    setCurrentUser();
    const temporaryCurrentOrder =
      JSON.parse(localStorage.getItem("currOrder"));
    currOrder(temporaryCurrentOrder);
    setCurrentOrder(temporaryCurrentOrder);
  }, []);

  const onBack = () => {
    clearMsg();
    history.push({ pathname: "/orders" });
    clearCurrentOrder();
    localStorage.removeItem("currOrder");
  };


  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-3">{messages.heading.orderReview}</h1>
        <input
          type="button"
          className="btn btn-primary mb-3"
          value="Go Back"
          onClick={onBack}
        />
        {msg && <ALerts msg={msg}></ALerts>}
        {isDraft && (
          <ALerts
            msg="Please approve or cancel all Items"
            type="error"
          ></ALerts>
        )}

        <ViewOrderCard currentOrder={currentOrder || currorder} />
        <ViewShippingAddressCard currentOrder={currentOrder || currorder} />
        <ViewBillingAddressCard currentOrder={currentOrder || currorder} />
        <div>
          <ViewOrderTable
            currentOrder={currentOrder || currorder}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
