import React, { useState } from "react";
import classnames from "classnames";
import { Multiselect } from "multiselect-react-dropdown";
import { uniq } from "lodash";
const AddClientCategory = ({
  changeCategoryValue,
  categoryCheckChange,
  addCategoryonArray,
  changeCategoryFromCategoryArray,
  categoryImageFileChange,
  categoryCheckLabel,
  categoryCheck,
  categoryDisable,
  categoryNameError,
  categoryError,
  categoryName,
  categoryImageError,
  categoryImageLabel,
  categoriesLabel,
  currCategoryArray,
  categoryArrayForOptions
}) => {

  const [fileKey, setFileKey] = useState(0);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-form-label label col-7">
              {categoryCheckLabel}
            </label>
            <div className="col-5">
              <input
                type="checkbox"
                onChange={categoryCheckChange}
                checked={categoryCheck}
                disabled={categoryDisable}
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8"></div>
      </div>
      {categoryCheck && (
        <div className="row mb-2">
          <div className="col-md-4 ">
            <div className="form-group ">
              <input
                type="text"
                className={classnames("form-control mr-1", {
                  "is-invalid": categoryNameError || categoryError,
                })}
                value={categoryName}
                name="category"
                onChange={(e) =>
                  changeCategoryValue("categoryName", e?.target?.value)
                }
                placeholder="category name"
                disabled={categoryDisable}
              />
              <small>{categoryNameError} </small>
              <br />
              <small>{categoryError} </small>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group ">
              <div className={!categoryImageError && "custom-file-border"}>
                <div className="custom-file">
                  <input
                    key={fileKey}
                    type="file"
                    name="files"
                    accept=".png,.jpeg,.jpg"
                    className={classnames("custom-file-input", {
                      "is-invalid": categoryImageError,
                    })}
                    placeholder="category image"
                    encType="multipart/form-data"
                    onChange={(e) => { categoryImageFileChange(e); setFileKey(fileKey + 1) }}
                    disabled={categoryDisable}
                    title=""
                    style={{ color: "transparent" }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile02"
                  >
                    {categoryImageLabel || null}
                  </label>
                </div>
              </div>

              <small>{categoryImageError} </small>
            </div>
          </div>
          <div className="col-md-2">
            <input
              type="button"
              value="Add Category"
              className="btn button  "
              disabled={categoryDisable}
              onClick={addCategoryonArray}
            />
          </div>
          <div className="col-md-3"></div>
        </div>
      )}
      <div className="row">
        {categoryCheck && (
          <>
            <label htmlFor="name" className="col-sm-3 col-form-label label">
              {categoriesLabel}
            </label>
            <div
              className="col-sm-7 ml-0 ml-sm-2 selectList px-0 px-0 px-md-3 px-sm-3"
              style={{ borderRadius: "5px" }}
            >
              <Multiselect
                displayValue="name"
                selectedValues={currCategoryArray.length && currCategoryArray}
                disablePreSelectedValues={categoryDisable}
                onSelect={(a) => changeCategoryFromCategoryArray(a)}
                onRemove={(a) => changeCategoryFromCategoryArray(a)}
                options={uniq(categoryArrayForOptions)}
              ></Multiselect>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddClientCategory;
