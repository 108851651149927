import React, { useState } from "react";
import classnames from "classnames";
import { uniq } from "lodash";
import { Multiselect } from "multiselect-react-dropdown";

const AllowDeliveryOption = ({
  toggleAllowDeliveryOption,
  addDeliveryOptions,
  deliveryOptionsLabel,
  allowDeliveryOption,
  deliveryOptionTextError,
  deliveryOptionPriceError,
  selectedDeliveryOptions,
  availableDeliveryOptions,
  updateSelectedDeliveryOptions,
  isDisable,
  deliveryOptionError
}) => {
  const [optionText, setOptionText] = useState("");
  const [optionPrice, setOptionPrice] = useState("");

  const handleAddDeliveryOption = () => {
    if (optionText && optionPrice) {
      const newOption = {
        optionText,
        optionPrice: parseFloat(optionPrice),
        isEnabled: false,
        id: Date.now()
      };
      addDeliveryOptions(newOption);
      setOptionText("");
      setOptionPrice("");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="" className="col-form-label label col-7">
              {deliveryOptionsLabel}
            </label>
            <div className="col-5">
              <input
                type="checkbox"
                onChange={toggleAllowDeliveryOption}
                checked={allowDeliveryOption}
                style={{ marginTop: "10px" }}
                disabled={isDisable}
              />
            </div>
          </div>
        </div>
      </div>

      {allowDeliveryOption && (
        <div className="row mb-2">
          <div className="col-md-4">
            <div className="form-group">
              <input
                type="text"
                className={classnames("form-control mr-1", {
                  "is-invalid": deliveryOptionTextError,
                })}
                value={optionText}
                onChange={(e) => setOptionText(e?.target?.value)}
                placeholder="Option Text"
                disabled={isDisable}
              />
              <small>{deliveryOptionTextError}</small>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <input
                type="number"
                className={classnames("form-control mr-1", {
                  "is-invalid": deliveryOptionPriceError,
                })}
                value={optionPrice}
                onChange={(e) => {
                  const value = e?.target?.value;
                  if (value?.length <= 4) {
                    setOptionPrice(value);
                  }
                }}
                placeholder="Option Price"
                maxLength={4}
                disabled={isDisable}
              />
              <small>{deliveryOptionPriceError}</small>
            </div>
          </div>
          <div className="col-md-2">
            <input
              type="button"
              value="Add Delivery Option"
              className="btn button"
              onClick={handleAddDeliveryOption}
              disabled={isDisable}
            />
          </div>
        </div>
      )}

      <div className="row mb-2">
        {allowDeliveryOption && (
          <>
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label label"
              style={{ height: '50px', }}
            >
              Delivery Options
            </label>
            <div className="col-sm-7 ml-0 ml-sm-2 px-0 px-0 px-md-3 px-sm-3">
              {/* Add is-invalid to the wrapper div */}
              <div
                style={{
                  border: deliveryOptionError ? '1px solid red' : 'none',
                  borderRadius: '4px'
                }}
              >
                <Multiselect
                  displayValue="optionText"
                  selectedValues={selectedDeliveryOptions}
                  onSelect={updateSelectedDeliveryOptions}
                  onRemove={updateSelectedDeliveryOptions}
                  options={uniq(availableDeliveryOptions)}
                  disabled={isDisable}
                  disablePreSelectedValues={isDisable}
                />
              </div>
              <small>{deliveryOptionError}</small>
            </div>

          </>
        )}
      </div>
    </>
  );
};

export default AllowDeliveryOption;
