import React from "react";
import moment from "moment";

const ViewOrderCard = ({ currentOrder }) => {

  const messages = {
    label: {
      orderRefNo: "Order Reference No",
      date: "Date",
      clientName: "Client Name",
      paymentStatus: "Payment Status",
      total: "Total",
      deliveryCharges: "Delivery Charges",
      orderDetail: "Order Detail",
      commentForDelivery:"Comment For Delivery"
    },
  };
  return (
    <div className=" card m-2  border-dark mb-5">
      <div className="card-header text-center adminCardHeader ">
        <h5>
          <a
            href="#collapse3"
            data-parent="#accordion"
            data-toggle="collapse"
            className="text-white"
          >
            {messages.label.orderDetail}
          </a>
        </h5>
      </div>
      <div id="collapse3" className="collapse show card p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.orderRefNo}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currentOrder?.orderReferenceNo}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.date}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={moment(currentOrder?.createAt).format("DD-MM-YYYY")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.clientName}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currentOrder?.client?.name}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.paymentStatus}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        currentOrder?.payment?.paymentStatus
                          ? currentOrder?.payment?.paymentStatus?.charAt(0).toUpperCase() + currentOrder?.payment?.paymentStatus.slice(1)
                          : ""
                      }
                    />
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.total}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currentOrder?.total}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.deliveryCharges}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={currentOrder?.deliveryCharges}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label htmlFor="" className="col-md-2 col-form-label">
                                        {messages.label.commentForDelivery}
                                    </label>
                                    <div className="col-md-10 px-0 px-md-3 px-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={ currentOrder.commentForDelivery}  // Joins the remaining values with a comma and space
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrderCard;
