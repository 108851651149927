const isHttpsUrl = (url) => {
    const regex = /^https:\/\//i;
    return regex?.test(url);
}

const convertToHttpsUrl = (url) => {
    const serviceUrlPart = '//wsrv.nl/?url='
    return serviceUrlPart + url;
};

const replaceMailaWithOstendo = (url) => {
    return url?.replace("maila", 'ostendo');
}


export const imageUtility = (imageUrl) => {
    if (!imageUrl) {
        return process.env.NO_IMAGE_URL;
    }
    else if (imageUrl.includes('maila')) {
        imageUrl = replaceMailaWithOstendo(imageUrl);
    }

    if (isHttpsUrl(imageUrl)) {
        return imageUrl;
    } else {
        const newUrl = convertToHttpsUrl(imageUrl);
        return newUrl;
    }
};
