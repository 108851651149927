import React, { useContext, useEffect, useState } from "react";
import { find, isEmpty } from "lodash";
import classnames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import AdminContext from "../../../contextAPI/AdminContext";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import spinner from "../../../assets/spinner.gif";

import { HtmlEditor, Inject, Link, Table, QuickToolbar, RichTextEditorComponent, Toolbar } from "@syncfusion/ej2-react-richtexteditor";
import AddClientDelivery from "./AddClientDelivery";
import AddClientPricingOption from "./AddClientPricingOption";
import AddClientCategory from "./AddClientCategory";
import AllowDeliveryOption from './AllowDeliveryOptions'

const AddClientForm = () => {
  const location = useLocation();
  const history = useHistory();

  const messages = {
    label: {
      clientName: "Client Name",
      clientStatus: "Client Status",
      mapToWarehouse: "Map to Warehouse",
      // ****************New Addition Started*********************//

      // FIles
      shipDetailsImage: "Shipping Detail File",
      termAndCondiFile: "Terms and Conditions File",
      privacyPolicyFile: "Privacy Policy File",
      contactDetailFile: "Contact Details File",

      // Options Radio's
      clientSiteIs: "Client Site is",

      // Web Site UI

      webFooterFontColorLable: "Footer Font Color",

      webFooterBackGroundColorLable: "Footer Background Color",
      footerFont: "Footer Font",
      // lol

      // ****************New Addition Ended*********************//

      imageForLanding: "Image File for Landing Page",
      logoForLanding: "Logo File for Landing Page",
      extensionForLanding: "Extension for Landing Page",
      textForLandingPage: "Text Block for Landing Page",
      textForContactUsPage: "Text Block for Contact Us Page",
      textForEmailHeader: "Text Block for Email Header",
      textForEmailFooter: "Text Block for Email Footer",
      enableOrderApproval: "Enable Order Approval",
      enablePayments: "Enable Payments",
      enableGuestAccess: "Enable Guest Access",
      pricingFromfeed: "Pricing from Feed",
      maintainPricing: "Maintain Pricing",
      noPricing: "No Pricing",
      deliveryCharges: "Delivery Charges",
      enableCategoryDisplay: "Enable Category Display",
      categories: "Categories:",
      standardSiteContentFontLable: "Standard Site Content Font",
      standdardSiteContentBackgroundColorLable: "Standard Site Content Background Color",
      standardSizeContentFontColorLabel: "Standard Site Content Font Colour",
      productWidgetBackgroundColorLable: "Product Widget Background Color",
      productImageBackgroundColorLable: "Product Image Background Color",
      productWidgetFontLable: "Product Widget Font",
      productWidgetFontColorLabel: "Product Widget Font Colour",
      productPriceFontLable: "Product Price Font",
      siteBackgroundColorLabel: "Site Background Color",
      clientEmail: "Email",
    },
    button: {
      save: "Save",
      exitWotoutSave: "Exit Without Save",
      exit: "Exit",
    },
  };

  let { addClient, currentClient, updateClient, clearCurrentClient, currCategory, clearCategory, error, removeError, maptoWhereHouse, warehouse, clientname, clearMsg, loading } = useContext(AdminContext);
  currCategory = localStorage.getItem("currCategory") ? JSON.parse(localStorage.getItem("currCategory")) : [];

  const toolbarSettings = {
    items: ["Bold", "CreateTable", "Italic", "Underline", "StrikeThrough", "FontName", "FontSize", "FontColor", "BackgroundColor", "LowerCase", "UpperCase", "|", "Formats", "Alignments", "OrderedList", "UnorderedList", "Outdent", "Indent", "|", "Undo", "Redo", "|", "CreateLink", "SuperScript", "SubScript", "FullScreen",],
  };
  let [clientDefinitionState, setClientDefinitionState] = useState({
    showcategory: false,
    showdelivery: false,
    clientName: "",
    clientStatus: "active",
    wareHouse: "",
    logoImageFile: "",
    logoImageFileLabel: "Choose file",

    // ****************New Addition Started*********************//
    shipDetailsImage: "",
    shipDetailsImageLabel: "Choose file",
    termAndCondiFile: "",
    termAndCondiFileLabel: "Choose file",
    privacyPolicyFile: "",
    privacyPolicyFileLabel: "Choose file",
    contactDetailFile: "",
    contactDetailFileLabel: "Choose file",
    clientSiteIs: "webstore",

    webFooterFontType: "",
    webFooterFontSize: "",
    webFooterFontColor: "#000000",
    webFooterBackGroundColor: "#000000",

    standdardSiteContentFontType: "",
    standdardSiteContentFontTypeSize: "",
    standardSizeContentFontColor: "#000000",
    standdardSiteContentBackgroundColor: "#000000",
    siteBackgroundColor: "#000000",


    productWidgetFontType: "",
    productWidgetFontSize: "",
    productWidgetFontColor: "#000000",
    productWidgetBackgroundColor: "#000000",
    productImageBackgroundColor: "#000000",
    productPriceFont: "",
    productPriceFontSize: "",

    // ****************New Addition Ended*********************//
    clientEmail: "",
    landingImageFile: "",
    landingImageFileLabel: "Choose file",
    extension: "",
    text: "",
    contactUsText: "",
    emailTextForHeader: "",
    emailTextForFooter: "",
    orderapprovel: false,
    payment: false,
    guestaccess: false,
    pricingFeed: false,
    maintainPrice: false,
    nopricing: true,
    deliverycharges: 0,
    categoryName: "",
    selectedcategory: "",
    selectedcategoryAllDeleted: false,
    categoryImage: "",
    categoryImageLabel: "Choose file",
    isCategoryEnabled: false,
    isDeliveryEnabled: false,
    categoryArray: [],
    tempCategoryArray: [],
    currentCategories: [],
    categoryNameError: "",
    categoryImageError: "",
    categoryError: "",
    categoriesImages: [],
    deliveryOptions: [],
    isDeliveryOptionEnable: false,
    deliveyOptionTextError: "",
    deliveyOptionPriceError: ""
  });


  const setCurrentClientData = () => {
    const clientState = { ...currentClient };

    setClientDefinitionState({
      ...clientDefinitionState,
      clientName: clientState?.name,
      text: clientState?.text,
      contactUsText: clientState?.contactUsText,
      emailTextForHeader: clientState?.emailTextForHeader,
      emailTextForFooter: clientState?.emailTextForFooter,

      shipDetailsImageLabel: clientState?.shipDetailsImage,
      termAndCondiFileLabel: clientState?.termAndCondiFile,
      privacyPolicyFileLabel: clientState?.privacyPolicyFile,
      contactDetailFileLabel: clientState?.contactDetailFile,

      clientSiteIs: clientState?.clientSiteIs,
      clientEmail: clientState?.clientEmail,
      shipDetailsImage: clientState?.shipDetailsImage,
      termAndCondiFile: clientState?.termAndCondiFile,
      privacyPolicyFile: clientState?.privacyPolicyFile,
      contactDetailFile: clientState?.contactDetailFile,
      webFooterFontType: clientState?.webFooterFontType,
      webFooterFontSize: clientState?.webFooterFontSize,
      webFooterFontColor: clientState?.webFooterFontColor,
      webFooterBackGroundColor: clientState?.webFooterBackGroundColor,
      standdardSiteContentFontType: clientState?.standdardSiteContentFontType,
      standdardSiteContentFontTypeSize: clientState?.standdardSiteContentFontTypeSize,
      standardSizeContentFontColor: clientState?.standardSizeContentFontColor,
      standdardSiteContentBackgroundColor: clientState?.standdardSiteContentBackgroundColor,
      siteBackgroundColor: clientState?.siteBackgroundColor,

      productWidgetFontType: clientState?.productWidgetFontType,
      productWidgetFontSize: clientState?.productWidgetFontSize,
      productWidgetFontColor: clientState?.productWidgetFontColor,
      productWidgetBackgroundColor: clientState?.productWidgetBackgroundColor,
      productImageBackgroundColor: clientState?.productImageBackgroundColor,
      productPriceFont: clientState?.productPriceFont,
      productPriceFontSize: clientState?.productPriceFontSize,

      clientStatus: clientState?.status,
      extension: clientState?.extension,
      orderapprovel: clientState?.orderApprovel,
      deliverycharges: clientState?.delivery,
      wareHouse: clientState?.warehouse,
      showcategory: clientState?.isCategoryEnabled,
      isCategoryEnabled: clientState?.isCategoryEnabled,
      isDeliveryEnabled: clientState?.isDeliveryEnabled,
      showdelivery: clientState?.isDeliveryEnabled,
      logoImageFile: clientState?.photo,
      logoImageFileLabel: clientState?.photo,
      landingImageFile: clientState?.landingPhoto,
      landingImageFileLabel: clientState?.landingPhoto,
      payment: clientState?.payment,
      guestaccess: clientState?.guestAccess,
      nopricing: clientState?.nopricing,
      pricingFeed: clientState?.pricingformfeed,
      maintainPrice: clientState?.maintainPricing,
      isDeliveryOptionEnable: clientState?.isDeliveryMultipleOptionEnabled,
      deliveryOptions: clientState?.deliveryOptions,
    });
  };

  useEffect(async () => {
    if (currentClient?.id) {
      setCurrentClientData();
    }
  }, [currentClient]);

  const updateClientDefinitionState = (field, value) => {
    setClientDefinitionState({
      ...clientDefinitionState,
      [field]: value,
    });
  };

  const changeCategoryIsShown = (e) => {
    let categoryShown = e.target.checked;
    setClientDefinitionState({
      ...clientDefinitionState,
      showcategory: categoryShown,
      isCategoryEnabled: categoryShown,
    });
  };

  const changeIsDelived = (e) => {
    let delivered = e.target.checked;
    setClientDefinitionState({
      ...clientDefinitionState,
      showdelivery: delivered,
      isDeliveryEnabled: delivered,
    });
  };


  const onChangeMultipleDeliveryOption = (e) => {
    let delivered = e?.target?.checked;
    setClientDefinitionState({
      ...clientDefinitionState,
      isDeliveryOptionEnable: delivered
    });
  };

  const onAddDeliveryOption = (newOption) => {
    const deliveryOptions = [...clientDefinitionState?.deliveryOptions] ?? [];
    deliveryOptions.push(newOption)
    setClientDefinitionState({
      ...clientDefinitionState,
      deliveryOptions: deliveryOptions
    });
  };


  const onSelectOption = (updatedOptions) => {
    const deliveryOptions = [...clientDefinitionState?.deliveryOptions] ?? [];
    const updatedOptionsToSet = deliveryOptions?.map((originalItem) => {
      // Check if the original item's id exists in the incoming array
      const matchedItem = updatedOptions?.find((incomingItem) => incomingItem?.id === originalItem?.id);

      // If there's a match, set 'enable' to true, otherwise keep it as is
      if (matchedItem) {
        return { ...originalItem, isEnabled: true };
      } else {
        return { ...originalItem, isEnabled: false };
      }

      return originalItem; // No match, return the item unchanged
    });
    setClientDefinitionState({
      ...clientDefinitionState,
      deliveryOptions: updatedOptionsToSet
    });
  }

  const onFileChange = (field, fileLabel, value) => {
    if (value) {
      setClientDefinitionState({
        ...clientDefinitionState,
        [field]: value,
        [fileLabel]: value.name,
      });
    }
  };

  const onCategoryFileChange = (e) => {
    const imageFile = e?.target?.files?.[0];
    if (imageFile) {
      let imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg?.test(imageFile?.name)) {
        const currentDate = moment().format("YYYY-MM-DD_HH-mm-ss-SSS");
        const newFileName = `${currentDate}.${imageFile?.name?.split('.').pop()}`;
        const currentFile = new File([imageFile], newFileName, {
          type: imageFile?.type,
        });
        const imageShortName = imageFile?.name?.slice(0, 30);
        let newImageArray = clientDefinitionState?.categoriesImages;
        newImageArray.push(currentFile);
        setClientDefinitionState({
          ...clientDefinitionState,
          categoriesImages: newImageArray,
          categoryImage: currentFile,
          categoryImageLabel: imageShortName,
          categoryImageError: "",
        });
      } else {
        setClientDefinitionState({
          ...clientDefinitionState,
          categoryImageError: "error in file format",
          categoryImage: null,
        });
      }
    } else {
      setClientDefinitionState({
        ...clientDefinitionState,
        categoryImageLabel: "Choose file",
        categoryImage: null,
      });
    }
  };

  const changeCategory = (a) => {
    setClientDefinitionState({
      ...clientDefinitionState,
      currentCategories: a,
      selectedcategory: a,
      selectedcategoryAllDeleted: !a.length,
    });
  };
  const onAddCategory = (e) => {
    const categoryRequiredError = "category name field is required";
    setClientDefinitionState({
      ...clientDefinitionState,
      categoryNameError: clientDefinitionState?.categoryName ? "" : categoryRequiredError,
    });
    const categories = clientDefinitionState?.categoryArray;
    const currentCategories = currCategory;
    if (clientDefinitionState?.categoryName) {
      if (clientDefinitionState?.categoryImage) {
        const found = find(categories, {
          name: clientDefinitionState?.categoryName,
        });
        const foundInCurrentCategories =
          currCategory?.length !== 0
            ? find(currentCategories, {
              name: clientDefinitionState?.categoryName,
            })
            : false;

        if (!found && !foundInCurrentCategories) {
          categories.push({
            name: clientDefinitionState?.categoryName,
            image: clientDefinitionState?.categoryImage?.name ? clientDefinitionState?.categoryImage?.name : "no-image.png",
          });

          setClientDefinitionState({
            ...clientDefinitionState,
            categoryName: "",
            categoryImage: null,
            categoryImageLabel: "Choose file",
            tempCategoryArray: categories,
            categoryError: "",
            categoryNameError: "",
            categoryImageError: "",
          });
        } else {
          const errorMessage = `category ${clientDefinitionState.categoryName} already exits.`;
          updateClientDefinitionState("categoryError", errorMessage);
        }
      } else {
        const found = find(categories, {
          name: clientDefinitionState.categoryName,
        });
        const foundInCurrentCategories =
          currCategory?.length !== 0
            ? find(currentCategories, {
              name: clientDefinitionState?.categoryName,
            })
            : false;
        if (!found && !foundInCurrentCategories) {
          categories.push({
            name: clientDefinitionState?.categoryName,
            image: "no-image.png",
          });
          setClientDefinitionState({
            ...clientDefinitionState,
            categoryName: "",
            categoryImage: null,
            categoryImageLabel: "Choose file",
            tempCategoryArray: categories,
            categoryNameError: "",
            categoryImageError: "",
            categoryError: "",
          });
        } else {
          const errorMessage = `category ${clientDefinitionState?.categoryName} already exits.`;
          setClientDefinitionState({
            ...clientDefinitionState,
            tempCategoryArray: categories,
            categoryError: errorMessage,
          });
        }
      }
    }
  };

  const changePricingFeed = (e, option) => {
    let isChecked = e.target.checked;
    if (option === "pricingFeed") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: isChecked,
        nopricing: false,
        maintainPrice: false,
      });
    } else if (option === "nopricing") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: false,
        nopricing: isChecked,
        maintainPrice: false,
      });
    } else if (option === "maintainPrice") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: false,
        nopricing: false,
        maintainPrice: isChecked,
      });
    }
  };

  const onExit = (e) => {
    clearCurrentClient();
    clearCategory();
    removeError();
    clearMsg();
    localStorage.removeItem("currClient");
    localStorage.removeItem("currCategory");
    history.push({
      pathname: "/clients",
      state: { reload: true, },
    });
  };

  useEffect(() => {
    maptoWhereHouse();
  }, []);

  const checkClientNameExitsOnOstendo = () => {
    let index;
    index = clientname.length && !isEmpty(clientDefinitionState.clientName) && clientname.findIndex((x) => x.toLocaleLowerCase() == clientDefinitionState.clientName.toLocaleLowerCase());
    if (index === -1) {
      return true;
    } else {
      return false;
    }
  };

  const checkClientWarehouseExitsOnOstendo = () => {
    let index;
    index = warehouse.length && !isEmpty(clientDefinitionState.wareHouse) && warehouse.findIndex((x) => x.toLocaleLowerCase() == clientDefinitionState.wareHouse.toLocaleLowerCase());
    index = index === -1 ? true : false;
    return index;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const clientStatusToSave = clientDefinitionState.clientStatus.toLocaleLowerCase();
    // const logoImageFileToSave = clientDefinitionState.file;
    const logoImageFileToSave = clientDefinitionState.logoImageFile;
    const landingImageFileToSave = clientDefinitionState.landingImageFile;

    // New Added
    const shipDetailsImage = clientDefinitionState.shipDetailsImage;
    const termAndCondiFile = clientDefinitionState.termAndCondiFile;
    const privacyPolicyFile = clientDefinitionState.privacyPolicyFile;
    const contactDetailFile = clientDefinitionState.contactDetailFile;
    const clientFormData = new FormData();
    if (!location.state.update) {
      clientFormData.append("image", logoImageFileToSave);
      clientFormData.append("landingImage", landingImageFileToSave);

      // New Added
      clientFormData.append("shipDetailsImage", shipDetailsImage);
      clientFormData.append("termAndCondiFile", termAndCondiFile);
      clientFormData.append("privacyPolicyFile", privacyPolicyFile);
      clientFormData.append("contactDetailFile", contactDetailFile);
      let newclient = {
        name: clientDefinitionState.clientName || (clientname && clientname[0]),
        status: clientStatusToSave,
        text: clientDefinitionState.text,
        contactUsText: clientDefinitionState.contactUsText,
        emailTextForHeader: clientDefinitionState.emailTextForHeader,
        emailTextForFooter: clientDefinitionState.emailTextForFooter,

        photo: logoImageFileToSave.name,
        // New Append
        shipDetailsImage: shipDetailsImage.name,
        termAndCondiFile: termAndCondiFile.name,
        privacyPolicyFile: privacyPolicyFile.name,
        contactDetailFile: contactDetailFile.name,

        clientEmail: clientDefinitionState.clientEmail,
        clientSiteIs: clientDefinitionState.clientSiteIs,
        webFooterFontType: clientDefinitionState.webFooterFontType,
        webFooterFontSize: clientDefinitionState.webFooterFontSize,
        webFooterFontColor: clientDefinitionState.webFooterFontColor,
        webFooterBackGroundColor: clientDefinitionState.webFooterBackGroundColor,
        standdardSiteContentFontType: clientDefinitionState.standdardSiteContentFontType,
        standdardSiteContentFontTypeSize: clientDefinitionState.standdardSiteContentFontTypeSize,
        standardSizeContentFontColor: clientDefinitionState.standardSizeContentFontColor,
        standdardSiteContentBackgroundColor: clientDefinitionState.standdardSiteContentBackgroundColor,
        siteBackgroundColor: clientDefinitionState.siteBackgroundColor,
        productWidgetBackgroundColor: clientDefinitionState.productWidgetBackgroundColor,
        productImageBackgroundColor: clientDefinitionState.productImageBackgroundColor,
        productWidgetFontType: clientDefinitionState.productWidgetFontType,
        productWidgetFontSize: clientDefinitionState.productWidgetFontSize,
        productWidgetFontColor: clientDefinitionState.productWidgetFontColor,
        productPriceFont: clientDefinitionState.productPriceFont,
        productPriceFontSize: clientDefinitionState.productPriceFontSize,

        // New Append Ended
        landingPhoto: landingImageFileToSave.name,
        orderApprovel: clientDefinitionState.orderapprovel,
        payment: clientDefinitionState.payment,
        guestAccess: clientDefinitionState.guestaccess,
        extension: clientDefinitionState.extension,
        warehouse: clientDefinitionState.wareHouse || (warehouse && warehouse[0]),
        delivery: parseFloat(clientDefinitionState.deliverycharges).toFixed(2),
        nopricing: clientDefinitionState.nopricing,
        pricingformfeed: clientDefinitionState.pricingFeed,
        maintainPricing: clientDefinitionState.maintainPrice,
        isCategoryEnabled: clientDefinitionState.isCategoryEnabled,
        isDeliveryEnabled: clientDefinitionState?.isDeliveryEnabled,
        deliveryOptions: clientDefinitionState?.deliveryOptions,
        isDeliveryMultipleOptionEnabled: clientDefinitionState?.isDeliveryOptionEnable
      };
      for (let key in newclient) {
        if (key == 'deliveryOptions') {
          clientFormData.append(key, JSON.stringify(newclient[key]));
        } else {
          clientFormData.append(key, newclient[key]);
        }
      }

      addClient(clientFormData, history, clientDefinitionState.currentCategories, clientDefinitionState.categoriesImages, location);
    } else {
      if (checkClientNameExitsOnOstendo() || checkClientWarehouseExitsOnOstendo()) {
        return;
      } else {
        let updateClientData = {
          clientEmail: clientDefinitionState.clientEmail,
          clientSiteIs: clientDefinitionState.clientSiteIs,
          webFooterFontType: clientDefinitionState.webFooterFontType,
          webFooterFontSize: clientDefinitionState.webFooterFontSize,
          webFooterFontColor: clientDefinitionState.webFooterFontColor,
          webFooterBackGroundColor: clientDefinitionState.webFooterBackGroundColor,
          standdardSiteContentFontType: clientDefinitionState.standdardSiteContentFontType,
          standdardSiteContentFontTypeSize: clientDefinitionState.standdardSiteContentFontTypeSize,
          standardSizeContentFontColor: clientDefinitionState.standardSizeContentFontColor,
          standdardSiteContentBackgroundColor: clientDefinitionState.standdardSiteContentBackgroundColor,
          siteBackgroundColor: clientDefinitionState.siteBackgroundColor,
          productWidgetBackgroundColor: clientDefinitionState.productWidgetBackgroundColor,
          productImageBackgroundColor: clientDefinitionState.productImageBackgroundColor,
          productWidgetFontType: clientDefinitionState.productWidgetFontType,
          productWidgetFontSize: clientDefinitionState.productWidgetFontSize,
          productWidgetFontColor: clientDefinitionState.productWidgetFontColor,
          productPriceFont: clientDefinitionState.productPriceFont,
          productPriceFontSize: clientDefinitionState.productPriceFontSize,

          name: clientDefinitionState.clientName,
          status: clientDefinitionState.clientStatus,
          text: clientDefinitionState?.text?.length ? clientDefinitionState.text : "",
          contactUsText: clientDefinitionState?.contactUsText?.length ? clientDefinitionState.contactUsText : "",
          emailTextForHeader: clientDefinitionState?.emailTextForHeader?.length ? clientDefinitionState.emailTextForHeader : "",
          emailTextForFooter: clientDefinitionState?.emailTextForFooter?.length ? clientDefinitionState.emailTextForFooter : "",
          warehouse: clientDefinitionState.wareHouse,
          orderApprovel: clientDefinitionState.orderapprovel,
          extension: clientDefinitionState.extension,
          payment: clientDefinitionState.payment,
          guestAccess: clientDefinitionState.guestaccess,
          nopricing: clientDefinitionState.nopricing,
          pricingformfeed: clientDefinitionState.pricingFeed,
          maintainPricing: clientDefinitionState.maintainPrice,
          delivery: parseFloat(clientDefinitionState.deliverycharges).toFixed(2) || parseFloat(0.0),
          isCategoryEnabled: clientDefinitionState.isCategoryEnabled,
          isDeliveryEnabled: clientDefinitionState.isDeliveryEnabled,
          deliveryOptions: clientDefinitionState?.deliveryOptions,
          isDeliveryMultipleOptionEnabled: clientDefinitionState?.isDeliveryOptionEnable
        };

        if (shipDetailsImage instanceof File) {
          updateClientData.shipDetailsImage = shipDetailsImage.name;
          clientFormData.append("shipDetailsImage", shipDetailsImage);
        }
        if (termAndCondiFile instanceof File) {
          updateClientData.termAndCondiFile = termAndCondiFile.name;
          clientFormData.append("termAndCondiFile", termAndCondiFile);
        }
        if (privacyPolicyFile instanceof File) {
          updateClientData.privacyPolicyFile = privacyPolicyFile.name;
          clientFormData.append("privacyPolicyFile", privacyPolicyFile);
        }

        if (contactDetailFile instanceof File) {
          updateClientData.contactDetailFile = contactDetailFile.name;
          clientFormData.append("contactDetailFile", contactDetailFile);
        }

        if (logoImageFileToSave instanceof File) {
          updateClientData.photo = logoImageFileToSave.name;
          clientFormData.append("image", logoImageFileToSave);
        }

        if (landingImageFileToSave instanceof File) {
          updateClientData.landingPhoto = landingImageFileToSave.name;
          clientFormData.append("landingImage", landingImageFileToSave);
        }

        for (let key in updateClientData) {
          if (key == 'deliveryOptions') {
            clientFormData.append(key, JSON.stringify(updateClientData[key]));
          } else {
            clientFormData.append(key, updateClientData[key]);
          }
        }

        const clientId = JSON.parse(localStorage.getItem("currClient"))?._id;
        if (clientDefinitionState.selectedcategoryAllDeleted) {
          updateClient(clientFormData, clientId, history, clientDefinitionState.currentCategories, location);
        } else {
          const categoriesToUpdate = clientDefinitionState.currentCategories.length
            ? clientDefinitionState.currentCategories
            : // : clientDefinitionState.categoryArray.filter((cat) => cat._id);
            currCategory.filter((cat) => cat._id);
          updateClient(clientFormData, clientId, history, categoriesToUpdate, clientDefinitionState.categoriesImages, location);
        }

        setClientDefinitionState({
          ...clientDefinitionState,
          selectedcategoryAllDeleted: false,
        });
        clearCurrentClient();
        clearCategory();
      }
    }
  };

  const removeExtraFromLabel = (label) => {
    const idIndex = label?.indexOf("/");
    if (idIndex !== -1) {
      return label?.substring(idIndex + 1);
    };
    return label;
  }


  return (
    <form onSubmit={onSubmit} encType="multipart/form-data">
      {loading && <img src={spinner} className="spinner" />}
      {!location.state.view && (
        <button type="submit" className="btn button mr-3">
          {messages.button.save}
        </button>
      )}
      <button type="button" className="btn button mr-3" onClick={onExit}>
        {!location.state.view ? messages.button.exitWotoutSave : messages.button.exit}
      </button>
      <div className="card border-dark p-5 mt-3">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.clientName}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <select
                  className={classnames("form-control sel-w selectList", {
                    "is-invalid": error?.name || checkClientNameExitsOnOstendo(),
                  })}
                  value={clientDefinitionState.clientName}
                  onChange={(e) => updateClientDefinitionState("clientName", e.target.value)}
                  disabled={location.state.view}
                >
                  {clientname.length &&
                    clientname.map((client) => (
                      <option value={client} key={client}>
                        {client}
                      </option>
                    ))}
                </select>
                <small>{error?.name}</small>
                <small>{checkClientNameExitsOnOstendo() && "ClientName Is Incorrect"}</small>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.clientStatus}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <select
                  id="selctClientStatus"
                  className={classnames("form-control selectList", {
                    "is-invalid": error?.status,
                  })}
                  onChange={(e) => updateClientDefinitionState("clientStatus", e.target.value)}
                  value={clientDefinitionState.clientStatus}
                  disabled={location.state.view}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
                <small>{error?.status}</small>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label ">
                {messages.label.mapToWarehouse}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <select
                  id="selctClientWareHouse"
                  className={classnames("form-control selectList", {
                    "is-invalid": error?.warehouse || checkClientWarehouseExitsOnOstendo(),
                  })}
                  value={clientDefinitionState.wareHouse}
                  onChange={(e) => updateClientDefinitionState("wareHouse", e.target.value)}
                  disabled={location.state.view}
                >
                  {warehouse.length &&
                    warehouse.map((whouse) => (
                      <option value={whouse} key={whouse}>
                        {whouse}
                      </option>
                    ))}
                </select>
                <small>{error?.warehouse}</small>
                <small>{checkClientWarehouseExitsOnOstendo() && "Warehouse Is Incorrect"}</small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages?.label?.clientSiteIs}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <div className="form-check">
                  <input
                    type="radio"
                    id="webstore"
                    name="clientSiteIs"
                    value="webstore"
                    // lol
                    checked={clientDefinitionState.clientSiteIs === "webstore"}
                    disabled={location.state.view}
                    className={classnames("form-check-input", {
                      "is-invalid": error?.clientSiteIs,
                    })}
                    onChange={(e) => updateClientDefinitionState("clientSiteIs", e.target.value)}
                  />
                  <label htmlFor="webstore" className="form-check-label">
                    Webstore
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="internalsite"
                    name="clientSiteIs"
                    value="internalsite"
                    checked={clientDefinitionState.clientSiteIs === "internalsite"}
                    disabled={location.state.view}
                    className={classnames("form-check-input", {
                      "is-invalid": error?.clientSiteIs,
                    })}
                    onChange={(e) => updateClientDefinitionState("clientSiteIs", e.target.value)}
                  />
                  <label htmlFor="internalsite" className="form-check-label">
                    Internal Site
                  </label>
                </div>
                {error && <small className="invalid-feedback">{error.clientSiteIs}</small>}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.logoForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.image && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg,.svg"
                      id="inputForImage"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.image,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("logoImageFile", "logoImageFileLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForImage" style={{ fontSize: "12px" }}>
                      {/* {clientDefinitionState.fileLabel} */}
                      {removeExtraFromLabel(clientDefinitionState?.logoImageFileLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.image}</small>
              </div>
            </div>

            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.imageForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.landingImage && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg"
                      id="inputForLandingImage"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.landingImage,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("landingImageFile", "landingImageFileLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForLandingImage" style={{ fontSize: "12px" }}>
                      {removeExtraFromLabel(clientDefinitionState?.landingImageFileLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.landingImage}</small>
              </div>
            </div>
            {/******************** New Chnages Started*************** */}

            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.shipDetailsImage}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.shipDetailsImage && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg,.pdf"
                      id="inputForShippingDetailsImage"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.shipDetailsImage,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("shipDetailsImage", "shipDetailsImageLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForShippingDetailsImage" style={{ fontSize: "12px" }}>
                      {removeExtraFromLabel(clientDefinitionState?.shipDetailsImageLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.shipDetailsImage}</small>
              </div>
            </div>
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.termAndCondiFile}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.termAndCondiFile && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg,.pdf"
                      id="inputForTermAndCondiFile"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.termAndCondiFile,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("termAndCondiFile", "termAndCondiFileLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForTermAndCondiFile" style={{ fontSize: "12px" }}>
                      {removeExtraFromLabel(clientDefinitionState?.termAndCondiFileLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.termAndCondiFile}</small>
              </div>
            </div>
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.privacyPolicyFile}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.privacyPolicyFile && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg,.pdf,"
                      id="inputForPrivacyPolicyFile"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.privacyPolicyFile,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("privacyPolicyFile", "privacyPolicyFileLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForPrivacyPolicyFile" style={{ fontSize: "12px" }}>
                      {removeExtraFromLabel(clientDefinitionState?.privacyPolicyFileLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.privacyPolicyFile}</small>
              </div>
            </div>
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.contactDetailFile}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.contactDetailFile && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg"
                      id="inputForContactDetailFile"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.contactDetailFile,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) => onFileChange("contactDetailFile", "contactDetailFileLabel", e.target.files[0])}
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label className="custom-file-label" htmlFor="inputForContactDetailFile" style={{ fontSize: "12px" }}>
                      {removeExtraFromLabel(clientDefinitionState?.contactDetailFileLabel)}
                    </label>
                  </div>
                </div>
                <small>{error?.contactDetailFile}</small>
              </div>
            </div>

            {/******************** New Chnages Ended*************** */}

            <div className="form-group row">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.extensionForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <input
                  type="text"
                  value={clientDefinitionState.extension}
                  disabled={location.state.view}
                  className={classnames("form-control ", {
                    "is-invalid": error?.extension,
                  })}
                  onChange={(e) => updateClientDefinitionState("extension", e.target.value)}
                />
                <small>{error?.extension}</small>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.clientEmail}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <input
                  type="text"
                  value={clientDefinitionState.clientEmail}
                  disabled={location.state.view}
                  className={classnames("form-control ", {
                    "is-invalid": error?.clientEmail,
                  })}
                  onChange={(e) => updateClientDefinitionState("clientEmail", e.target.value)}
                />
                <small>{error?.clientEmail}</small>
              </div>
            </div>



          </div>
        </div>

        <div className="form-group row">
          <div>
            <label htmlFor="name" className="col-form-label px-3 ">
              {messages.label.textForLandingPage}
            </label>
          </div>
          <div
            className={classnames("form-control textarea-border", {
              "is-invalid": error?.text,
            })}
          >
            <RichTextEditorComponent
              height={450}
              maxLength={1000}
              enabled={!location.state.view}
              enablePersistence={false}
              value={clientDefinitionState.text}
              toolbarSettings={toolbarSettings}
              change={(e) => {
                updateClientDefinitionState("text", e.value);
              }}
              paste={(e) => e.preventDefault()}
              drag={(e) => e.preventDefault()}
            >
              <Inject
                services={[
                  Toolbar,
                  // Image,
                  Link,
                  HtmlEditor,
                  QuickToolbar,
                  Table,
                ]}
              />
            </RichTextEditorComponent>
          </div>
          <small>{error?.text}</small>
        </div>

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.enableOrderApproval}</label>
              <div className="col-5">
                <input type="checkbox" onChange={(e) => updateClientDefinitionState("orderapprovel", e.target.checked)} defaultChecked={currentClient.orderApprovel} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.enablePayments}</label>
              <div className="col-5">
                <input type="checkbox" onChange={(e) => updateClientDefinitionState("payment", e.target.checked)} defaultChecked={currentClient.payment} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.enableGuestAccess}</label>
              <div className="col-5">
                <input type="checkbox" onChange={(e) => updateClientDefinitionState("guestaccess", e.target.checked)} defaultChecked={currentClient.guestAccess} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>

        <AddClientPricingOption changePricingOption={changePricingFeed} labelpricingFromfeed={messages.label.pricingFromfeed} labelmaintainPricing={messages.label.maintainPricing} labelnoPricing={messages.label.noPricing} pricingFeedCheck={clientDefinitionState.pricingFeed} maintainPricingCheck={clientDefinitionState.maintainPrice} noPricingCheck={clientDefinitionState.nopricing} pricingOptionDisable={location.state.view} />

        <AddClientDelivery
          changeDeliveryCheck={changeIsDelived}
          changeDeliveryValue={updateClientDefinitionState}
          showDelivery={clientDefinitionState.showdelivery}
          deliveryDisabled={location.state.view}
          deliveryLabel={messages.label.deliveryCharges}
          deliveryError={error?.delivery}
          deliveryValue={clientDefinitionState.deliverycharges}
        />

        <AllowDeliveryOption
          toggleAllowDeliveryOption={onChangeMultipleDeliveryOption}
          addDeliveryOptions={onAddDeliveryOption}
          updateSelectedDeliveryOptions={onSelectOption}
          deliveryOptionsLabel="Allow Delivery Options"
          allowDeliveryOption={clientDefinitionState.isDeliveryOptionEnable}
          deliveryOptionTextError={clientDefinitionState?.deliveyOptionTextError}
          deliveryOptionPriceError={clientDefinitionState?.deliveyOptionPriceError}
          selectedDeliveryOptions={clientDefinitionState?.deliveryOptions?.filter(x => x?.isEnabled == true)}
          availableDeliveryOptions={clientDefinitionState?.deliveryOptions?.filter(x => x?.isEnabled == false)}
          isDisable={location.state.view}
          deliveryOptionError={error?.deliveryOption}
        />
        <AddClientCategory
          changeCategoryValue={updateClientDefinitionState}
          categoryCheckChange={changeCategoryIsShown}
          addCategoryonArray={onAddCategory}
          changeCategoryFromCategoryArray={changeCategory}
          categoryImageFileChange={onCategoryFileChange}
          categoryCheckLabel={messages.label.enableCategoryDisplay}
          categoryCheck={clientDefinitionState.showcategory}
          categoryDisable={location.state.view}
          categoryNameError={clientDefinitionState?.categoryNameError}
          categoryError={clientDefinitionState?.categoryError}
          categoryName={clientDefinitionState.categoryName}
          categoryImageError={clientDefinitionState?.categoryImageError}
          categoryImageLabel={clientDefinitionState.categoryImageLabel}
          categoriesLabel={messages.label.categories}
          currCategoryArray={localStorage.getItem("currCategory") ? JSON.parse(localStorage.getItem("currCategory")) : []}
          categoryArrayForOptions={clientDefinitionState.categoryArray}
        />
        <hr className="hr" />
        {/* New Addition Started */}
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.webFooterBackGroundColorLable}</label>
              <div className="col-5">
                <input value={clientDefinitionState.webFooterBackGroundColor} type="color" onChange={(e) => updateClientDefinitionState("webFooterBackGroundColor", e.target.value)} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.footerFont}</label>
              <div className="col-5">
                <select value={clientDefinitionState.webFooterFontType} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("webFooterFontType", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Font
                  </option>
                  <option value="arial" className="">
                    Arial-(Default)
                  </option>
                  <option value="timesnewromen" className="">
                    Times New Roman
                  </option>
                  <option value="verdana" className="">
                    Verdana
                  </option>
                  <option value="calibri" className="">
                    Calibri
                  </option>
                  <option value="georgia" className="">
                    Georgia
                  </option>
                  <option value="tahoma" className="">
                    Tahoma
                  </option>
                  <option value="helvetica" className="">
                    Helvetica
                  </option>
                  <option value="couriernew" className="">
                    Courier New
                  </option>
                  <option value="palatino" className="">
                    Palatino
                  </option>
                  <option value="garamond" className="">
                    Garamond
                  </option>
                  <option value="bookantiqua" className="">
                    Book Antiqua
                  </option>
                  <option value="lucidasans" className="">
                    Lucida Sans
                  </option>
                  <option value="centurygothic" className="">
                    Century Gothic
                  </option>
                  {/* Add more fonts as needed */}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <div className="col-5">
                <select value={clientDefinitionState.webFooterFontSize} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("webFooterFontSize", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Size
                  </option>
                  <option value="11pt" className="">
                    11pt - (Default)
                  </option>
                  <option value="2px" className="">
                    2px
                  </option>
                  <option value="4px" className="">
                    4px
                  </option>
                  <option value="6px" className="">
                    6px
                  </option>
                  <option value="8px" className="">
                    8px
                  </option>
                  <option value="10px" className="">
                    10px
                  </option>
                  <option value="12px" className="">
                    12px
                  </option>
                  <option value="14px" className="">
                    14px
                  </option>
                  <option value="16px" className="">
                    16px
                  </option>
                  <option value="18px" className="">
                    18px
                  </option>
                  <option value="20px" className="">
                    20px
                  </option>
                  <option value="22px" className="">
                    22px
                  </option>
                  <option value="24px" className="">
                    24px
                  </option>
                  <option value="26px" className="">
                    26px
                  </option>
                  <option value="28px" className="">
                    28px
                  </option>
                  <option value="30px" className="">
                    30px
                  </option>
                  <option value="32px" className="">
                    32px
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.webFooterFontColorLable}</label>
              <div className="col-5">
                <input value={clientDefinitionState.webFooterFontColor} type="color" onChange={(e) => updateClientDefinitionState("webFooterFontColor", e.target.value)} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>

        <hr className="hr" />

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.siteBackgroundColorLabel}</label>
              <div className="col-5">
                <input value={clientDefinitionState.siteBackgroundColor} type="color" onChange={(e) => updateClientDefinitionState("siteBackgroundColor", e.target.value)} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.standdardSiteContentBackgroundColorLable}</label>
              <div className="col-5">
                <input value={clientDefinitionState.standdardSiteContentBackgroundColor} type="color" onChange={(e) => updateClientDefinitionState("standdardSiteContentBackgroundColor", e.target.value)} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.standardSiteContentFontLable}</label>
              <div className="col-5">
                <select value={clientDefinitionState.standdardSiteContentFontType} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("standdardSiteContentFontType", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Font
                  </option>
                  <option value="arial" className="">
                    Arial-(Default)
                  </option>
                  <option value="timesnewromen" className="">
                    Times New Roman
                  </option>
                  <option value="verdana" className="">
                    Verdana
                  </option>
                  <option value="calibri" className="">
                    Calibri
                  </option>
                  <option value="georgia" className="">
                    Georgia
                  </option>
                  <option value="tahoma" className="">
                    Tahoma
                  </option>
                  <option value="helvetica" className="">
                    Helvetica
                  </option>
                  <option value="couriernew" className="">
                    Courier New
                  </option>
                  <option value="palatino" className="">
                    Palatino
                  </option>
                  <option value="garamond" className="">
                    Garamond
                  </option>
                  <option value="bookantiqua" className="">
                    Book Antiqua
                  </option>
                  <option value="lucidasans" className="">
                    Lucida Sans
                  </option>
                  <option value="centurygothic" className="">
                    Century Gothic
                  </option>
                  {/* Add more fonts as needed */}

                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <div className="col-5">
                <select value={clientDefinitionState.standdardSiteContentFontTypeSize} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("standdardSiteContentFontTypeSize", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Size
                  </option>
                  <option value="11pt" className="">
                    11pt - (Default)
                  </option>
                  <option value="2px" className="">
                    2px
                  </option>
                  <option value="4px" className="">
                    4px
                  </option>
                  <option value="6px" className="">
                    6px
                  </option>
                  <option value="8px" className="">
                    8px
                  </option>
                  <option value="10px" className="">
                    10px
                  </option>
                  <option value="12px" className="">
                    12px
                  </option>
                  <option value="14px" className="">
                    14px
                  </option>
                  <option value="16px" className="">
                    16px
                  </option>
                  <option value="18px" className="">
                    18px
                  </option>
                  <option value="20px" className="">
                    20px
                  </option>
                  <option value="22px" className="">
                    22px
                  </option>
                  <option value="24px" className="">
                    24px
                  </option>
                  <option value="26px" className="">
                    26px
                  </option>
                  <option value="28px" className="">
                    28px
                  </option>
                  <option value="30px" className="">
                    30px
                  </option>
                  <option value="32px" className="">
                    32px
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.standardSizeContentFontColorLabel}</label>
              <div className="col-5">
                <input value={clientDefinitionState.standardSizeContentFontColor} type="color" onChange={(e) => updateClientDefinitionState("standardSizeContentFontColor", e.target.value)} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>

        <hr className="hr" />

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.productWidgetBackgroundColorLable}</label>
              <div className="col-5">
                <input value={clientDefinitionState.productWidgetBackgroundColor} type="color" onChange={(e) => updateClientDefinitionState("productWidgetBackgroundColor", e.target.value)} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.productImageBackgroundColorLable}</label>
              <div className="col-5">
                <input value={clientDefinitionState.productImageBackgroundColor} type="color" onChange={(e) => updateClientDefinitionState("productImageBackgroundColor", e.target.value)} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>




        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.productWidgetFontLable}</label>
              <div className="col-5">
                <select value={clientDefinitionState.productWidgetFontType} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("productWidgetFontType", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Font
                  </option>
                  <option value="arial" className="">
                    Arial-(Default)
                  </option>
                  <option value="timesnewromen" className="">
                    Times New Roman
                  </option>
                  <option value="verdana" className="">
                    Verdana
                  </option>
                  <option value="calibri" className="">
                    Calibri
                  </option>
                  <option value="georgia" className="">
                    Georgia
                  </option>
                  <option value="tahoma" className="">
                    Tahoma
                  </option>
                  <option value="helvetica" className="">
                    Helvetica
                  </option>
                  <option value="couriernew" className="">
                    Courier New
                  </option>
                  <option value="palatino" className="">
                    Palatino
                  </option>
                  <option value="garamond" className="">
                    Garamond
                  </option>
                  <option value="bookantiqua" className="">
                    Book Antiqua
                  </option>
                  <option value="lucidasans" className="">
                    Lucida Sans
                  </option>
                  <option value="centurygothic" className="">
                    Century Gothic
                  </option>
                  {/* Add more fonts as needed */}

                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <div className="col-5">
                <select value={clientDefinitionState.productWidgetFontSize} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("productWidgetFontSize", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Size
                  </option>
                  <option value="11pt" className="">
                    11pt - (Default)
                  </option>
                  <option value="2px" className="">
                    2px
                  </option>
                  <option value="4px" className="">
                    4px
                  </option>
                  <option value="6px" className="">
                    6px
                  </option>
                  <option value="8px" className="">
                    8px
                  </option>
                  <option value="10px" className="">
                    10px
                  </option>
                  <option value="12px" className="">
                    12px
                  </option>
                  <option value="14px" className="">
                    14px
                  </option>
                  <option value="16px" className="">
                    16px
                  </option>
                  <option value="18px" className="">
                    18px
                  </option>
                  <option value="20px" className="">
                    20px
                  </option>
                  <option value="22px" className="">
                    22px
                  </option>
                  <option value="24px" className="">
                    24px
                  </option>
                  <option value="26px" className="">
                    26px
                  </option>
                  <option value="28px" className="">
                    28px
                  </option>
                  <option value="30px" className="">
                    30px
                  </option>
                  <option value="32px" className="">
                    32px
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.productWidgetFontColorLabel}</label>
              <div className="col-5">
                <input value={clientDefinitionState.productWidgetFontColor} type="color" onChange={(e) => updateClientDefinitionState("productWidgetFontColor", e.target.value)} disabled={location.state.view} style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
        </div>






        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">{messages.label.productPriceFontLable}</label>
              <div className="col-5">
                <select value={clientDefinitionState.productPriceFont} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("productPriceFont", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Font
                  </option>
                  <option value="arial" className="">
                    Arial-(Default)
                  </option>
                  <option value="timesnewromen" className="">
                    Times New Roman
                  </option>
                  <option value="verdana" className="">
                    Verdana
                  </option>
                  <option value="calibri" className="">
                    Calibri
                  </option>
                  <option value="georgia" className="">
                    Georgia
                  </option>
                  <option value="tahoma" className="">
                    Tahoma
                  </option>
                  <option value="helvetica" className="">
                    Helvetica
                  </option>
                  <option value="couriernew" className="">
                    Courier New
                  </option>
                  <option value="palatino" className="">
                    Palatino
                  </option>
                  <option value="garamond" className="">
                    Garamond
                  </option>
                  <option value="bookantiqua" className="">
                    Book Antiqua
                  </option>
                  <option value="lucidasans" className="">
                    Lucida Sans
                  </option>
                  <option value="centurygothic" className="">
                    Century Gothic
                  </option>
                  {/* Add more fonts as needed */}

                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <div className="col-5">
                <select value={clientDefinitionState.productPriceFontSize} className={classnames("form-control sel-w selectList")} onChange={(e) => updateClientDefinitionState("productPriceFontSize", e.target.value)} disabled={location.state.view}>
                  <option value="null" selected>
                    Select Size
                  </option>
                  <option value="11pt" className="">
                    11pt - (Default)
                  </option>
                  <option value="2px" className="">
                    2px
                  </option>
                  <option value="4px" className="">
                    4px
                  </option>
                  <option value="6px" className="">
                    6px
                  </option>
                  <option value="8px" className="">
                    8px
                  </option>
                  <option value="10px" className="">
                    10px
                  </option>
                  <option value="12px" className="">
                    12px
                  </option>
                  <option value="14px" className="">
                    14px
                  </option>
                  <option value="16px" className="">
                    16px
                  </option>
                  <option value="18px" className="">
                    18px
                  </option>
                  <option value="20px" className="">
                    20px
                  </option>
                  <option value="22px" className="">
                    22px
                  </option>
                  <option value="24px" className="">
                    24px
                  </option>
                  <option value="26px" className="">
                    26px
                  </option>
                  <option value="28px" className="">
                    28px
                  </option>
                  <option value="30px" className="">
                    30px
                  </option>
                  <option value="32px" className="">
                    32px
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <hr className="hr" />
        <div className="form-group row">
          <div>
            <label htmlFor="name" className="col-form-label px-3 ">
              {messages.label.textForContactUsPage}
            </label>
          </div>
          <div
            className={classnames("form-control textarea-border", {
              "is-invalid": error?.textForContactUsPage,
            })}
          >
            <RichTextEditorComponent
              height={450}
              enabled={!location.state.view}
              enablePersistence={false}
              value={clientDefinitionState.contactUsText}
              toolbarSettings={toolbarSettings}
              maxLength={1000}
              change={(e) => {
                updateClientDefinitionState("contactUsText", e.value);
              }}
              paste={(e) => e.preventDefault()}
              drag={(e) => e.preventDefault()}
            >
              <Inject
                services={[
                  Toolbar,
                  // Image,
                  Link,
                  HtmlEditor,
                  QuickToolbar,
                  Table,
                ]}
              />
            </RichTextEditorComponent>
          </div>
          <small>{error?.textForContactUsPage}</small>
        </div>

        <hr className="hr" />
        <div className="form-group row">
          <div>
            <label htmlFor="name" className="col-form-label px-3 ">
              {messages.label.textForEmailHeader}
            </label>
          </div>
          <div
            className={classnames("form-control textarea-border", {
              "is-invalid": error?.textForEmailHeader,
            })}
          >
            <RichTextEditorComponent
              height={450}
              enabled={!location.state.view}
              enablePersistence={false}
              value={clientDefinitionState.emailTextForHeader}
              toolbarSettings={toolbarSettings}
              maxLength={500}
              change={(e) => {
                updateClientDefinitionState("emailTextForHeader", e.value);
              }}
              paste={(e) => e.preventDefault()}
              drag={(e) => e.preventDefault()}
            >
              <Inject
                services={[
                  Toolbar,
                  // Image,
                  Link,
                  HtmlEditor,
                  QuickToolbar,
                  Table,
                ]}
              />
            </RichTextEditorComponent>
          </div>
          <small>{error?.textForEmailHeader}</small>
        </div>

        <hr className="hr" />
        <div className="form-group row">
          <div>
            <label htmlFor="name" className="col-form-label px-3 ">
              {messages.label.textForEmailFooter}
            </label>
          </div>
          <div
            className={classnames("form-control textarea-border", {
              "is-invalid": error?.textForEmailFooter,
            })}
          >
            <RichTextEditorComponent
              height={450}
              enabled={!location.state.view}
              enablePersistence={false}
              value={clientDefinitionState.emailTextForFooter}
              toolbarSettings={toolbarSettings}
              maxLength={500}
              change={(e) => {
                updateClientDefinitionState("emailTextForFooter", e.value);
              }}
              paste={(e) => e.preventDefault()}
              drag={(e) => e.preventDefault()}
            >
              <Inject
                services={[
                  Toolbar,
                  // Image,
                  Link,
                  HtmlEditor,
                  QuickToolbar,
                  Table,
                ]}
              />
            </RichTextEditorComponent>
          </div>
          <small>{error?.textForEmailFooter}</small>
        </div>
        {/* New Addition Ended */}
      </div>
    </form>
  );
};



export default AddClientForm;
