import React from "react";
import { imageUtility } from "../../../common/imageUtility";

const ViewOrderTable = ({ currentOrder }) => {
  const messages = {
    label: {
      sku: "SKU",
      name: "Name",
      image: "Image",
      description: "Description", // Label for size and color description
      quantity: "Quantity",
      unitPrice: "Price",
      totalPrice: "Total Amount",
      unitPriceWithTax: "Price Include Tax",
    },
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table mt-5 order-table table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">{messages.label.sku}</th>
            <th scope="col">{messages.label.name}</th>
            <th scope="col">{messages.label.image}</th>
            <th scope="col">{messages.label.description}</th> {/* Add comment header */}
            <th scope="col">{messages.label.quantity}</th>
            <th scope="col">{messages.label.unitPrice}</th>
            <th scope="col">{messages.label.unitPriceWithTax}</th>
            <th scope="col">{messages.label.totalPrice}</th>
          </tr>
        </thead>
        <tbody>
          {currentOrder.lineItems &&
            currentOrder.lineItems.map((item) => {
              const sizeDescription = item?.selectedQuantity?.sizeCode
                ? `Size: ${item?.selectedQuantity?.sizeCode}`
                : '';
              const colorDescription = item?.selectedQuantity?.colorCode
                ? `Color: ${item?.selectedQuantity?.colorCode}`
                : '';

              const descriptionValue = [sizeDescription, colorDescription]
                .filter(Boolean)
                .join('   ');

              return (
                <tr key={item?._id}>
                  <td>{item?.productId}</td>
                  <td>{item?.description}</td>
                  <td>
                    {item?.imageURL && (
                      <img
                        src={imageUtility(item?.imageURL)}
                        alt="Product"
                        className="img-thumbnail"
                        style={{ width: "50px", height: "50px", objectFit: "cover" }}
                      />
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={descriptionValue}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="btn-small form-control in-w product-quan addQuantity"
                      value={item?.quantity}
                      disabled
                      min="1"
                    />
                  </td>
                  <td>{parseFloat(item?.unitPrice).toFixed(2)}</td>
                  <td>{parseFloat(item?.unitPriceWithTax).toFixed(2)}</td>
                  <td>{parseFloat(item?.totalPrice).toFixed(2)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewOrderTable;
