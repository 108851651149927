import React from "react";

const ViewShippingAddressCard = ({  currentOrder }) => {

  const messages = {
    label: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      PhoneNumber: "Phone Number",
      address: "Address",
      shippingAddress: "Shipping Address"
    },
  };
  return (
    <div className=" card m-2  border-dark mb-5">
    <div className="card-header text-center adminCardHeader ">
        <h5>
            <a
                href="#collapse2"
                data-parent="#accordion"
                data-toggle="collapse"
                className="text-white"
            >
                {messages.label.shippingAddress}
            </a>
        </h5>
    </div>
    <div id="collapse2" className="collapse  card p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.firstName}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currentOrder?.shippingAddress?.firstName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.lastName}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={currentOrder?.shippingAddress?.lastName}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.email}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currentOrder?.shippingAddress?.email}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.PhoneNumber}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={currentOrder?.shippingAddress?.phoneNumber}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-2 col-form-label">
                    {messages.label.address}
                  </label>
                  <div className="col-md-10 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={[
                        currentOrder?.shippingAddress?.addressLine1,
                        currentOrder?.shippingAddress?.addressLine2,
                        currentOrder?.shippingAddress?.suburb,
                        currentOrder?.shippingAddress?.state,
                        currentOrder?.shippingAddress?.postalCode,
                      ]
                        .filter(Boolean)  // This filters out empty/undefined/null values
                        .join(', ')}  // Joins the remaining values with a comma and space
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewShippingAddressCard;
